import React, { useRef } from 'react'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import { Languages } from 'i18n/config'
import Button, { ButtonGroup, ButtonType } from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent from 'components/SectionContent'
import MatterCard from 'components/MatterCard'
import Scheme from 'components/Scheme'
import PageBottomCard from 'components/PageBottomCard'
import VideoPlayer from 'components/VideoPlayer'
import classnames from 'classnames'
import * as styles from './index.module.less'
import { FocusState } from './index.tw'
import 'styles/base.less'

import i18n from 'i18n/en/i18n_focus.json'

const Focus: React.FC = (props) => {
  const demoUrl = `/api/client/user/router?demo=Retail`
  const contactUrl = '/form/parade/'
  const paradeUrl = ''
  const curVideo = useRef<any>(null)
  const lang = Languages.EN
  return (
    <Layout {...props}>
      {/* <FocusState demoUrl={demoUrl} paradeUrl={paradeUrl} lang={Languages.EN} i18n={i18n} /> */}

      <Seo
        title={i18n['i18n_focus_head_pageTitle']}
        description={i18n['i18n_focus_head_description']}
        keywords={i18n['i18n_focus_head_keywords']}
        saTitle=""
      />
      <main className={classnames(styles.Focus)}>
        <SolutionsBanner
          className={styles.Banner}
          title={i18n['i18n_focus_main_1']}
          subTitle={i18n['i18n_focus_main_2']}
          desc={i18n['i18n_focus_main_3']}
          imgUrl={
            <img
              src={require('assets/images/product/focus/tw/banner_img.gif').default}
              className={styles.BannerIcon}
              alt=""
            />
          }
          buttonGroup={
            <ButtonGroup className={styles.BannerButtonGroup}>
              {demoUrl && (
                <Button btnType={ButtonType.Default} href={demoUrl}>
                  {i18n['i18n_focus_main_4']}
                </Button>
              )}
              {i18n['i18n_focus_main_5'] && (
                <Button onClick={() => curVideo.current.playVideo()}>{i18n['i18n_focus_main_5']}</Button>
              )}
            </ButtonGroup>
          }
        />
        <SectionContent className={styles.SceneContent}>
          {[
            {
              title: `${i18n['i18n_focus_main_21']}\n${i18n['i18n_focus_main_22']}`,
              desc: `${i18n['i18n_focus_main_23']}\n${i18n['i18n_focus_main_24']}\n${i18n['i18n_focus_main_25']}`,
              thumb: require('assets/images/product/focus/en/1.png').default,
              alt: '',
              thumbWidth: 700,
              buttonText: i18n['i18n_focus_main_57'],
              buttonHref: paradeUrl,
              reverse: false,
            },
            {
              title: `${i18n['i18n_focus_main_27']}\n${i18n['i18n_focus_main_28']}`,
              desc: `${i18n['i18n_focus_main_29']}`,
              thumb: require('assets/images/product/focus/en/2.png').default,
              alt: '',
              thumbWidth: 700,
              buttonText: i18n['i18n_focus_main_57'],
              buttonHref: paradeUrl,
              reverse: true,
            },
            {
              title: `${i18n['i18n_focus_main_31']}\n${i18n['i18n_focus_main_32']}”`,
              desc: `${i18n['i18n_focus_main_33']}`,
              thumb: require('assets/images/product/focus/en/7.png').default,
              alt: '',
              thumbWidth: 700,
              buttonText: i18n['i18n_focus_main_57'],
              buttonHref: paradeUrl,
              reverse: false,
            },
            {
              title: `${i18n['i18n_focus_main_35']}\n${i18n['i18n_focus_main_36']}`,
              desc: `${i18n['i18n_focus_main_37']}`,
              thumb: require('assets/images/product/focus/en/3.png').default,
              alt: '',
              thumbWidth: 700,
              buttonText: i18n['i18n_focus_main_57'],
              buttonHref: paradeUrl,
              reverse: true,
            },
          ].map((item) => (
            <MatterCard className={styles.MatterCardItem} key={item.title} {...item} />
          ))}
        </SectionContent>
        <SectionContent className={styles.Advantage} title={i18n['i18n_focus_main_39']} black>
          <Scheme
            columnNum={3}
            dataSource={[
              {
                title: i18n['i18n_focus_main_40'],
                desc: i18n['i18n_focus_main_41'],
                icon: require('assets/images/product/focus/tw/sf-advance-1.png').default,
                alt: 'icon',
              },
              {
                title: i18n['i18n_focus_main_42'],
                desc: i18n['i18n_focus_main_44'],
                icon: require('assets/images/product/focus/tw/sf-advance-2.png').default,
                alt: 'icon',
              },
              {
                title: i18n['i18n_focus_main_45'],
                desc: i18n['i18n_focus_main_46'],
                icon: require('assets/images/product/focus/tw/sf-advance-3.png').default,
                alt: 'icon',
              },
              {
                title: i18n['i18n_focus_main_47'],
                desc: i18n['i18n_focus_main_48'],
                icon: require('assets/images/product/focus/tw/sf-advance-4.png').default,
                alt: 'icon',
              },
              {
                title: i18n['i18n_focus_main_49'],
                desc: i18n['i18n_focus_main_50'],
                icon: require('assets/images/product/focus/tw/sf-advance-5.png').default,
                alt: 'icon',
              },
              {
                title: i18n['i18n_focus_main_51'],
                desc: i18n['i18n_focus_main_53'],
                icon: require('assets/images/product/focus/tw/sf-advance-6.png').default,
                alt: 'icon',
              },
            ]}
          />
        </SectionContent>
        <PageBottomCard
          title={i18n['i18n_focus_main_54']}
          desc={i18n['i18n_focus_main_55']}
          leftButtonText={i18n['i18n_focus_main_56']}
          leftButtonHref={contactUrl}
        />
      </main>
      <VideoPlayer
        videoOpt={{
          sources: 'https://video.sensorsdata.com/sv/25346513-1771404ddb2/25346513-1771404ddb2.mp4',
        }}
        fuRef={curVideo}
      />
    </Layout>
  )
}

export default Focus
